import { getSession, isSameSession, resetSession,resetSessionStorage } from "../../../utilities/sessionHelpers";
import { showAlert } from "../../../shared/ConfirmPopup";
import { CustomerLoginURL } from "../../../services/cpServices/cpServices";

export const cpSessionKeys = {
    CONTACT_TYPE:"ContactType",
    LAST_CONTRACT_STATUS: "LastContractStatus",
    PARENT_ID:"ParentID",
    CUSTOMER_ID:"CustomerID",    
    IS_ADAGENCY:"IsAdAgency",
    SALES_REP_ID:"SalesRepID",
    ID:"ID",
    SELECTED_CUSTOMER:"SelectedCustomer",
    IS_EMAILID_VERIFICATION_ENABLED:"IsEmailIDVerificationEnabled",
    IS_STATE_AS_TEXTBOX:"IsStateAsTextBox",
    EMAIL_ID:"EmailID",
    CLIENT_ID:"ClientID",
    CUSTOMER_IDS:"CustomerIDs",
    CLIENT_ADDRESS:"ClientAddress",
    IS_MIRABEL20:"IsMirabel20",
    IS_AUTHENTICATED:"IsAuthenticated",
    ACCESS_TOKEN:"AccessToken",
    REFRESH_TOKEN:"RefreshToken",
    DATE_FORMAT:"DateFormat",
    CURRENCY_SYMBOL:"CurrencySymbol",
    CLIENT_ADDRESS:"ClientAddress",
    CLIENT_NAME:"ClientName",
    CUSTOMER_PORTAL_LOGO: "CustomerPortalLogo",
    COMPANY_NAME: "CompanyName",
    CULTURE_INFO: "CultureInfo",
    CULTURE_UI: "CultureUI",
    CLIENT_SUB_DOMAIN: "ClientSubDomain",
    URL_SECTION: "UrlSection",
    CP_SESSION_CPLOGIN: "CPSessionCpLogin",
    IS_API_USER:"IsApiUser",
    IS_CONTACT_INACTIVE: "InActive"
}

function getCPSession(key) {
    if (isSameSession()) {
        return getSession(key);
    }
    showAlert({
        message: `We noticed you've signed onto a new company & contact on the portal: ${getSession(cpSessionKeys.COMPANY_NAME)}. To make sure your browser knows where you are, the previous session has been ended.`,
    }).then(resp => {
        resetSessionStorage();
        window.open(CustomerLoginURL, '_self');
    })
}

export const cpSession = () => {

    const EmailID = () => { return getCPSession(cpSessionKeys.EMAIL_ID) };

    const ClientID = () => { return getCPSession(cpSessionKeys.CLIENT_ID) };

    const CustomerIDs = () => { return getCPSession(cpSessionKeys.CUSTOMER_IDS) };

    const ClientAddress = () => { return getCPSession(cpSessionKeys.CLIENT_ADDRESS) };

    const IsMirabel20 = () => { return getCPSession(cpSessionKeys.IS_MIRABEL20) };

    const IsAuthenticated = () => { return getSession(cpSessionKeys.IS_AUTHENTICATED) };

    const AccessToken = () => { return getCPSession(cpSessionKeys.ACCESS_TOKEN) };

    const RefreshToken = () => { return getCPSession(cpSessionKeys.REFRESH_TOKEN) };

    const ClientSubDomain = () => { return getCPSession(cpSessionKeys.CLIENT_SUB_DOMAIN) };

    const CustomerID = () => { return getCPSession(cpSessionKeys.CUSTOMER_ID) };

    const SelectedCustomerID = () => { return getCPSession(cpSessionKeys.CUSTOMER_ID) };

    const DateFormat = () => { return (getCPSession(cpSessionKeys.DATE_FORMAT) === "" ? "MM/dd/yyyy" : getCPSession(cpSessionKeys.DATE_FORMAT)) };

    const CurrencySymbol = () => { return (getCPSession(cpSessionKeys.CURRENCY_SYMBOL) === "" ? "$" : getCPSession(cpSessionKeys.CURRENCY_SYMBOL)) };

    const ClientName = () => { return getCPSession(cpSessionKeys.CLIENT_NAME) };

    const CustomerPortalLogo = () => { return getCPSession(cpSessionKeys.CUSTOMER_PORTAL_LOGO) };

    const CompanyName = () => { return getCPSession(cpSessionKeys.COMPANY_NAME) };

    const CultureInfo = () => { return getCPSession(cpSessionKeys.CULTURE_INFO) };

    const CultureUI = () => { return getCPSession(cpSessionKeys.CULTURE_UI) ? getCPSession(cpSessionKeys.CULTURE_UI) :"en-US"};

    const ParentID = () => { return getCPSession(cpSessionKeys.PARENT_ID) };

    const LastContractStatus = () => { return getCPSession(cpSessionKeys.LAST_CONTRACT_STATUS) };

    const IsStateAsTextBox = () => { return getCPSession(cpSessionKeys.IS_STATE_AS_TEXTBOX) };

    const ContactType = () => { return getCPSession(cpSessionKeys.CONTACT_TYPE) };

    const SalesRepID = () => { return getCPSession(cpSessionKeys.SALES_REP_ID) };

    const IsAgency = () => { return getCPSession(cpSessionKeys.IS_ADAGENCY) };

    const ID = () => { return getCPSession(cpSessionKeys.ID) };

    const SelectedCustomer = () => { return getCPSession(cpSessionKeys.SELECTED_CUSTOMER) };

    const isCompany = () => { return getCPSession(cpSessionKeys.PARENT_ID) == getCPSession(cpSessionKeys.CUSTOMER_ID) };

    const UrlSection = () => { return getCPSession(cpSessionKeys.URL_SECTION) };

    const CPSessionCpLogin = () => { return getSession(cpSessionKeys.CP_SESSION_CPLOGIN) };
    
    const IsApiUser = () => { return getSession(cpSessionKeys.IS_API_USER) }
    return {
        EmailID, ClientID, CustomerIDs, ClientAddress, IsMirabel20, IsAuthenticated,
        AccessToken, RefreshToken, ClientSubDomain, CustomerID, DateFormat, CurrencySymbol,
        ClientName, CustomerPortalLogo, CompanyName, CultureInfo, CultureUI, ParentID, LastContractStatus, ContactType,
        SalesRepID, IsAgency, SelectedCustomerID, ID, SelectedCustomer, IsStateAsTextBox,isCompany,UrlSection,CPSessionCpLogin,IsApiUser
    }
}