import { enumSavedSearchPageTypes } from "../../../utilities/enums";
import queryString from "query-string";
import { ProductionModuleSummaryFields} from "./PMSummaryFieldsJson";

const queryValues = queryString.parse(window.location.search.toLowerCase());
const { done, arrived } = queryValues;
const SalesModuleSummaryFields = [
  // type: 1, Whole Number Format
  // type: 2, DecimalNumberWithNoCurrency Format
  // type: 3, DecimalNumberWithCurrency Format
  // type: 6, Percentage Format
  // type: 7, WholeNumberWithCurrency Format
  {
    SummaryFieldID: 1,
    ColorCode: "#4b89dc",
    DisplayValue: "12",
    Title: "Total Records",
    SortOrder: 1,
    IsSelected: true,
    DataIndex: "TotalRecords",
    type: 1,
  },
  {
    SummaryFieldID: 2,
    ColorCode: "#4b89dc",
    DisplayValue: "345",
    Title: "Total Pages",
    SortOrder: 2,
    IsSelected: true,
    DataIndex: "TotalPages",
    type: 2,
  },
  {
    SummaryFieldID: 3,
    ColorCode: "#4b89dc",
    DisplayValue: "678",
    Title: "Page Rate",
    SortOrder: 3,
    IsSelected: true,
    DataIndex: "PageRate",
    type: 1,
  },
  {
    SummaryFieldID: 4,
    ColorCode: "#4b89dc",
    DisplayValue: "12345",
    Title: "Total Barter",
    SortOrder: 4,
    IsSelected: true,
    DataIndex: "TotalBarter",
    type: 3,
  },
  {
    SummaryFieldID: 5,
    ColorCode: "#4b89dc",
    DisplayValue: "1234567",
    Title: "Total Gross",
    SortOrder: 5,
    IsSelected: true,
    DataIndex: "TotalGross",
    type: 3,
  },
  {
    SummaryFieldID: 6,
    ColorCode: "#4b89dc",
    DisplayValue: "1234567",
    Title: "Total Net",
    SortOrder: 6,
    IsSelected: true,
    DataIndex: "TotalNet",
    type: 3,
  },
  { 
    SummaryFieldID: 7, 
    ColorCode: "#4b89dc", 
    DisplayValue: "27529", 
    Title: "Total Cash", 
    SortOrder: 7,
     IsSelected: false, 
     DataIndex: "TotalCash",
     type:3
     }

];

const ProposalModuleSummaryFields=[
  {
    SummaryFieldID: 1,
    ColorCode: "#4b89dc",
    DisplayValue: "123",
    Title: "# of Proposals",
    SortOrder: 1,
    IsSelected: true,
    DataIndex: "Proposals",
    type: 1,
  },
  {
    SummaryFieldID: 2,
    ColorCode: "#4b89dc",
    DisplayValue: "4567",
    Title: "Proposals Amount",
    SortOrder: 2,
    IsSelected: true,
    DataIndex: "ProposalsAmount",
    type: 3,
  },
  {
    SummaryFieldID: 3,
    ColorCode: "#4b89dc",
    DisplayValue: "123",
    Title: "Active Proposals",
    SortOrder: 3,
    IsSelected: true,
    DataIndex: "ActiveProposals",
    type: 1,
  },
  {
    SummaryFieldID: 4,
    ColorCode: "#4b89dc",
    DisplayValue: "4567",
    Title: "Active Proposals Total",
    SortOrder: 4,
    IsSelected: true,
    DataIndex: "ActiveProposalsAmount",
    type: 3,
  },
  {
    SummaryFieldID: 5,
    ColorCode: "#4b89dc",
    DisplayValue: "123",
    Title: "Sent Proposals",
    SortOrder: 5,
    IsSelected: true,
    DataIndex: "SentProposals",
    type: 1,
  },
  {
    SummaryFieldID: 6,
    ColorCode: "#4b89dc",
    DisplayValue: "4567",
    Title: "Sent Proposals Total",
    SortOrder: 6,
    IsSelected: true,
    DataIndex: "SentProposalsAmount",
    type: 3,
  },
  {
    SummaryFieldID: 7,
    ColorCode: "#4b89dc",
    DisplayValue: "123",
    Title: "Approved Proposals",
    SortOrder: 7,
    IsSelected: true,
    DataIndex: "ApprovedProposals",
    type: 1,
  },
  {
    SummaryFieldID: 8,
    ColorCode: "#4b89dc",
    DisplayValue: "4567",
    Title: "Approved Proposals Total",
    SortOrder:8,
    IsSelected: true,
    DataIndex: "ApprovedProposalsAmount",
    type: 3,
  },
  {
    SummaryFieldID: 9,
    ColorCode: "#4b89dc",
    DisplayValue: "123",
    Title: "# of Opportunities",
    SortOrder: 9,
    IsSelected: false,
    DataIndex: "TotIds",
    type: 1,
  },
  {
    SummaryFieldID: 10,
    ColorCode: "#4b89dc",
    DisplayValue: "4567",
    Title: "Opportunity Amount",
    SortOrder: 10,
    IsSelected: false,
    DataIndex: "TotOppAmt",
    type: 7,
  },
  {
    SummaryFieldID: 11,
    ColorCode: "#4b89dc",
    DisplayValue: "89",
    Title: "Total Opportunity Won",
    SortOrder: 11,
    IsSelected: false,
    DataIndex: "Won",
    type: 1,
  },
  {
    SummaryFieldID: 12,
    ColorCode: "#4b89dc",
    DisplayValue: "120",
    Title: "Total Opportunity Open",
    SortOrder: 12,
    IsSelected: false,
    DataIndex: "Open",
    type: 1,
  },
  {
    SummaryFieldID: 13,
    ColorCode: "#4b89dc",
    DisplayValue: "348",
    Title: "Total Opportunity Lost",
    SortOrder: 13,
    IsSelected: false,
    DataIndex: "Lost",
    type: 1,
  },
  {
    SummaryFieldID: 14,
    ColorCode: "#4b89dc",
    DisplayValue: "456",
    Title: "Opportunity Win Total",
    SortOrder: 14,
    IsSelected: false,
    DataIndex: "WinTotal",
    type: 7,
  },
  { 
    SummaryFieldID: 15, 
    ColorCode: "#4b89dc", 
    DisplayValue: "100", 
    Title: "Opportunity Win %", 
    SortOrder: 15,
     IsSelected: false, 
     DataIndex: "WinRatio",
     type:6
  },
]

const OpportunityModuleSummaryFields=[
  {
    SummaryFieldID: 1,
    ColorCode: "#4b89dc",
    DisplayValue: "123",
    Title: "# of Opportunities",
    SortOrder: 1,
    IsSelected: true,
    DataIndex: "TotIds",
    type: 1,
  },
  {
    SummaryFieldID: 2,
    ColorCode: "#4b89dc",
    DisplayValue: "4567",
    Title: "Opportunity Amount",
    SortOrder: 2,
    IsSelected: true,
    DataIndex: "TotOppAmt",
    type: 7,
  },
  {
    SummaryFieldID: 3,
    ColorCode: "#4b89dc",
    DisplayValue: "89",
    Title: "Total Opportunity Won",
    SortOrder: 3,
    IsSelected: true,
    DataIndex: "Won",
    type: 1,
  },
  {
    SummaryFieldID: 4,
    ColorCode: "#4b89dc",
    DisplayValue: "120",
    Title: "Total Opportunity Open",
    SortOrder: 4,
    IsSelected: true,
    DataIndex: "Open",
    type: 1,
  },
  {
    SummaryFieldID: 5,
    ColorCode: "#4b89dc",
    DisplayValue: "348",
    Title: "Total Opportunity Lost",
    SortOrder: 5,
    IsSelected: true,
    DataIndex: "Lost",
    type: 1,
  },
  {
    SummaryFieldID: 6,
    ColorCode: "#4b89dc",
    DisplayValue: "456",
    Title: "Opportunity Win Total",
    SortOrder: 6,
    IsSelected: true,
    DataIndex: "WinTotal",
    type: 7,
  },
  { 
    SummaryFieldID: 7, 
    ColorCode: "#4b89dc", 
    DisplayValue: "100", 
    Title: "Opportunity Win %", 
    SortOrder: 7,
     IsSelected: true, 
     DataIndex: "WinRatio",
     type:6
  },
  {
    SummaryFieldID: 8,
    ColorCode: "#4b89dc",
    DisplayValue: "123",
    Title: "# of Proposals",
    SortOrder: 8,
    IsSelected: false,
    DataIndex: "Proposals",
    type: 1,
  },
  {
    SummaryFieldID: 9,
    ColorCode: "#4b89dc",
    DisplayValue: "4567",
    Title: "Proposals Amount",
    SortOrder: 9,
    IsSelected: false,
    DataIndex: "ProposalsAmount",
    type: 3,
  },
  {
    SummaryFieldID: 10,
    ColorCode: "#4b89dc",
    DisplayValue: "123",
    Title: "Converted To Contracts",
    SortOrder: 10,
    IsSelected: false,
    DataIndex: "ConvertedToContracts",
    type: 1,
  },
  {
    SummaryFieldID: 11,
    ColorCode: "#4b89dc",
    DisplayValue: "4567",
    Title: "Converted Proposals Total",
    SortOrder: 11,
    IsSelected: false,
    DataIndex: "ConvertedAmount",
    type: 3,
  },
  {
    SummaryFieldID: 12,
    ColorCode: "#4b89dc",
    DisplayValue: "123",
    Title: "Active Proposals",
    SortOrder: 12,
    IsSelected: false,
    DataIndex: "ActiveProposals",
    type: 1,
  },
  {
    SummaryFieldID: 13,
    ColorCode: "#4b89dc",
    DisplayValue: "4567",
    Title: "Active Proposals Total",
    SortOrder: 13,
    IsSelected: false,
    DataIndex: "ActiveProposalsAmount",
    type: 3,
  },
  {
    SummaryFieldID: 14,
    ColorCode: "#4b89dc",
    DisplayValue: "123",
    Title: "Sent Proposals",
    SortOrder: 14,
    IsSelected: false,
    DataIndex: "SentProposals",
    type: 1,
  },
  {
    SummaryFieldID: 15,
    ColorCode: "#4b89dc",
    DisplayValue: "4567",
    Title: "Sent Proposals Total",
    SortOrder: 15,
    IsSelected: false,
    DataIndex: "SentProposalsAmount",
    type: 3,
  },
  {
    SummaryFieldID: 16,
    ColorCode: "#4b89dc",
    DisplayValue: "123",
    Title: "Approved Proposals",
    SortOrder: 16,
    IsSelected: false,
    DataIndex: "ApprovedProposals",
    type: 1,
  },
  {
    SummaryFieldID: 17,
    ColorCode: "#4b89dc",
    DisplayValue: "4567",
    Title: "Approved Proposals Total",
    SortOrder: 17,
    IsSelected: false,
    DataIndex: "ApprovedProposalsAmount",
    type: 3,
  }
]

const CampaignSearchSummaryFields = [
  // type: 1, Whole Number Format
  // type: 2, DecimalNumberWithNoCurrency Format
  // type: 3, DecimalNumberWithCurrency Format
  // type: 6, Percentage Format
  // type: 7, WholeNumberWithCurrency Format
  {
    SummaryFieldID: 1,
    ColorCode: "#4b89dc",
    DisplayValue: "120",
    Title: "Total Campaigns",
    SortOrder: 1,
    IsSelected: true,
    DataIndex: "TotalCampaigns",
    type: 1,
  },
  {
    SummaryFieldID: 2,
    ColorCode: "#4b89dc",
    DisplayValue: "400",
    Title: "Total Recipients",
    SortOrder: 2,
    IsSelected: true,
    DataIndex: "TotalRecipients",
    type: 1,
  },
  {
    SummaryFieldID: 3,
    ColorCode: "#4b89dc",
    DisplayValue: "345",
    Title: "Total Delivered",
    SortOrder: 3,
    IsSelected: true,
    DataIndex: "TotalDelivered",
    type: 1,
  },
  {
    SummaryFieldID: 4,
    ColorCode: "#4b89dc",
    DisplayValue: "678",
    Title: "Total Opened",
    SortOrder: 4,
    IsSelected: true,
    DataIndex: "TotalOpened",
    type: 1,
  },
  {
    SummaryFieldID: 5,
    ColorCode: "#4b89dc",
    DisplayValue: "12345",
    Title: "Total Clicks",
    SortOrder: 5,
    IsSelected: false,
    DataIndex: "TotalClicks",
    type: 1,
  },
  {
    SummaryFieldID: 6,
    ColorCode: "#4b89dc",
    DisplayValue: "12",
    Title: "Total Spam",
    SortOrder: 6,
    IsSelected: false,
    DataIndex: "TotalSpam",
    type: 1,
  },
  {
    SummaryFieldID: 7,
    ColorCode: "#4b89dc",
    DisplayValue: "40",
    Title: "Total Bounce",
    SortOrder: 7,
    IsSelected: false,
    DataIndex: "TotalBounce",
    type: 1,
  },

];
export const sendData = (PageData) => {
  if (done || arrived) {
    return PageData.map((item) => {
      if (item.DataIndex === "TotalAdArrived" && item.SummaryFieldID === 2) {        // '2' means min stage in that product type Default
         item.Title = arrived;
      } else if (item.DataIndex === "TotalAdDone" && item.SummaryFieldID === 3) {    // '3' means max stage in that product type Default
        item.Title = done;
      }
      return item;
    });
  } else {
    return PageData;
  }
};

export const getDefaultSummaryFields = (reportCode , dynamic = false) => {
  switch (reportCode) {
    case enumSavedSearchPageTypes.SalesReport:
      return SalesModuleSummaryFields;
    case enumSavedSearchPageTypes.OrderSearch:
      let length = ProductionModuleSummaryFields.length * 2;
      let dynamicSummaryFields = JSON.parse(localStorage.getItem('dynamicSummaryFields'));  //Getting Dynamic fields based in dynamic parameter
      let dynamicData =[];
      if(dynamicSummaryFields && dynamicSummaryFields.length > 0){       //Checking for the Stages present in the Current View
        dynamicSummaryFields.map((item)=>{      
          dynamicData.push({
            SummaryFieldID: item.StageId + length,
            ColorCode: item.ColorCode,
            DisplayValue: "1234%",
            Title: item.Name,
            IsSelected: false,
            DataIndex: 'Total' + item.Name.replace(' ',''),
            type: 6,
          })
        })
        if(dynamicData.length >0 && dynamic){   //dynamic = true means data present in db
          return sendData([...dynamicData])
        }
        else
          return sendData([...dynamicData,...ProductionModuleSummaryFields])
      }else{
        return sendData(ProductionModuleSummaryFields);
      }
      return;
    case enumSavedSearchPageTypes.Opportunity:
      return OpportunityModuleSummaryFields; 
    case enumSavedSearchPageTypes.Proposal:
      return ProposalModuleSummaryFields;  
    case enumSavedSearchPageTypes.CampaignSearch:
      return CampaignSearchSummaryFields;
    default:
  }
};

